import React, { useState, createContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InfoScreen from './components/InfoScreen';
import QuestionScreen from './components/QuestionScreen';
import SummaryScreen from './components/SummaryScreen';

export const SurveyContext = createContext(); // Dodaj tę linijkę

const App = () => {
  const [score, setScore] = useState(0);
  

  return (
    <Router>
      <SurveyContext.Provider value={{ score, setScore }}> 
        <Routes>
          <Route path="/" element={<InfoScreen />} />
          <Route path="/questions" element={<QuestionScreen score={score} setScore={setScore} />} />
          <Route path="/summary" element={<SummaryScreen />} />
        </Routes>
      </SurveyContext.Provider> 
    </Router>
  );
}

export default App;