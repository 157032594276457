import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SurveyContext } from '../App';
import { Container, Title, Text, TextSmall, Button, Input, Label, RangeContainer, RangeLabel, StyledRangeInput,ImageContainer, StyledImage} from './styles';
import { css, keyframes } from '@emotion/react';
import { questions } from './Questions.js';
import logotypy from '../img/6c1fe26f.png';


const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const QuestionScreen = () => {

  const { score, setScore} = useContext(SurveyContext);
  const navigate = useNavigate();
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(Math.floor(questions[currentQuestionId].answers.length / 2));
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [prevAnswer, setPrevAnswer] = useState(null);
  const [answers, setAnswers] = useState(Array(questions.length).fill(0));



  useEffect(() => {
    if (isTransitioning) {
      setTimeout(() => {
        setIsTransitioning(false);
      }, 500);
    }
  }, [isTransitioning]);


  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
  };

  useEffect(() => {
    setSelectedAnswer(answers[currentQuestionId] || Math.floor(questions[currentQuestionId].answers.length / 2));
  }, [currentQuestionId]);

  

  const handleSubmit = () => {
    const points = questions[currentQuestionId].answers[selectedAnswer].points;
    const newAnswers = [...answers];
    newAnswers[currentQuestionId] = selectedAnswer;
    setAnswers(newAnswers);
    setIsTransitioning(true);
    setScore(score + points);
    setPrevAnswer(points);
    setIsTransitioning(true);

    setTimeout(() => {
      if (currentQuestionId < questions.length - 1) {
        setCurrentQuestionId(currentQuestionId + 1);
        setSelectedAnswer(Math.floor(questions[currentQuestionId + 1].answers.length / 2));
      } else {
        navigate('/summary');
      }
    }, 500);

  };

  const handleBack = () => {
    if (currentQuestionId > 0) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentQuestionId(currentQuestionId - 1);
      }, 500);
    }

    const points = questions[currentQuestionId-1].answers[answers[currentQuestionId-1]].points;
    setScore(score - points);

    setSelectedAnswer(answers[currentQuestionId - 1]);
  };

  const transitionStyles = css`
  animation: ${isTransitioning ? fadeOut : fadeIn} 0.5s;
`;

return (
    <Container>
      <Title><br/>System analizy sytuacji przedsiębiorstwa</Title>
      <Title css={transitionStyles}>
        {questions[currentQuestionId].text}
      </Title>
      <RangeContainer>
        <RangeLabel>{questions[currentQuestionId].answers[0].text}</RangeLabel>
        <StyledRangeInput 
          css={transitionStyles}
          type='range'
          min='0'
          max={questions[currentQuestionId].answers.length - 1}
          // max={questions[currentQuestionId].answers.length}
          value={selectedAnswer}
          onChange={handleAnswerChange}
        />
        <RangeLabel>{questions[currentQuestionId].answers[questions[currentQuestionId].answers.length - 1].text}</RangeLabel>
      </RangeContainer>
      <Text>{questions[currentQuestionId].answers[selectedAnswer].text}</Text>
      {currentQuestionId > 0 && (
        <Button css={transitionStyles} onClick={handleBack}>
          Poprzednie pytanie
        </Button>
      )}
      <Button css={transitionStyles} onClick={handleSubmit}>
        Następne pytanie
      </Button>
      <TextSmall css={transitionStyles}>
        Pytanie {currentQuestionId + 1} z {questions.length}
      </TextSmall>
      <ImageContainer>
  <StyledImage src={logotypy} />
</ImageContainer>
<TextSmall>Zadanie publiczne pn. "Opracowanie strategii zmian transformacji cyfrowej w zakresie podejście do zarządzania - Agile”<br/> dofinansowane ze środków z budżetu Województwa Mazowieckiego. <br/><br/></TextSmall>

    </Container>
  );
}

export default QuestionScreen;
