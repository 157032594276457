import React, { useState, useContext } from 'react';
import { SurveyContext } from '../App';
import { Link } from 'react-router-dom';
import { Container, Title, Text, TextSmallReg, ButtonEmail, Input, Label, StyledUl, StyledLi,TextUnderline, ImageContainer, StyledImage, TextSmall} from './styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';
import logotypy from '../img/6c1fe26f.png';

const SummaryScreen = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const { score } = useContext(SurveyContext); // Uzyskanie dostępu do stanu 'score'

  const handleSubmit = () => {
    if (!validator.isMobilePhone(phoneNumber, 'pl-PL')) {
      toast.error('Podany numer telefonu jest nieprawidłowy.');
      return;
    }
    if (isAgreed) {
      toast.success(`Dziękujemy za udział w ankiecie i zostawienie swojego numeru telefonu.`);
    } else {
      toast.error('Aby zapisać się, musisz zaakceptować regulamin.');
    }
  };

  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
  };

  const handleShowMore = () => {
    setShowMore(showMore ? false : true);
  };

  const getScoreText = (score) => {
    if (score <= 20) return 'Brak wyraźnych wskazań na potrzebę wdrożenia Agile';
    // if (score <= 35) return 'Średnie wskazanie na potrzebę wdrożenia Agile';
    if (score <= 35) return 'Niewielkie wskazanie na potrzebę wdrożenia Agile';
    if (score <= 45) return 'Średnie wskazanie na potrzebę wdrożenia Agile';
    if (score <= 55) return 'Silne wskazanie na potrzebę wdrożenia Agile';
  }

  const whyPlacePhone = (score) => {
    if (score <= 20) return <Text>Na podstawie przeprowadzonej analizy chcielibyśmy zaproponować Twojej organizacji odpowiednie, dopasowane do niej usługi cyfrowej transformacji i zarekomendować dalsze procesy usług. 
    Wypełnij formularz kontaktowy i zostaw nam swój numer telefonu. Skontaktujemy się z Tobą i zaoferujemy kolejne fazy usługi!</Text>;
    if (score <= 55) return  <Text>Wygląda na to, że Twoja firma bardzo skorzystałaby na wdrożeniu metodologii Agile. Jeżeli jesteś zainteresowany transformacją cyfrową, która przyczyni się do poprawy skuteczności Twojej firmy, zostaw nam swój adres mailowy, a my się z Tobą skontaktujemy!</Text>;
  }

  const lotOfText = () => {
    return <>
     <Text>Agile może Ci pomóc poprzez:</Text>
          <StyledUl>
            <StyledLi>Szybsze dostosowanie się do zmian: dzięki wykorzystaniu Agile możliwe jest szybsze i bardziej elastyczne dostosowywanie do zmian i wymagań rynku, co jest szczególnie ważne w erze szybko zmieniającej się technologii i rynku.</StyledLi>
            <StyledLi>Zwiększenie efektywności: Agile zachęca do ciągłego doskonalenia i ulepszania procesów, co może prowadzić do zwiększenia efektywności i jakości oferowanych produktów czy usług.</StyledLi>
            <StyledLi>Poprawa komunikacji i współpracy: wykorzystanie Agile wymaga wspomaganego cyfrowo obiegu informacji w organizacji, co może poprawić komunikację i współpracę między członkami zespołu i klientami, co jest kluczowe w procesie transformacji cyfrowej.</StyledLi>
            <StyledLi>Umożliwienie innowacji: Agile to powtarzalność i eksperymentowanie, co może prowadzić do wprowadzenia nowych rozwiązań i ulepszeń, które są kluczowe dla sukcesu w erze cyfrowej.</StyledLi>
      </StyledUl>
      <TextUnderline onClick={handleShowMore}>Pokaż mniej</TextUnderline>
    </>;
  }


    const newLocal = <Container>
      <Title><br/>System analizy sytuacji przedsiębiorstwa</Title>
        <Title>Podsumowanie</Title>
        
        <Text>{getScoreText(score)}</Text>


        {!showMore && (
        <TextUnderline onClick={handleShowMore}>Pokaż więcej informacji o Agile.</TextUnderline>
      )}
      {showMore && (lotOfText()
      
      )}

<Label>
        {whyPlacePhone(score)}
        <Text> <Input type='tel' value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} placeholder='W formacie: 321654987' />
        <TextSmallReg>
            <input type="checkbox" checked={isAgreed} onChange={handleCheckboxChange} />
            Akceptuję <Link to="http://klasterict.pl/">regulamin</Link>
       </TextSmallReg>
        </Text>
        </Label>
        <ButtonEmail onClick={handleSubmit}>Zapisz</ButtonEmail>
        <ToastContainer />
        <ImageContainer>
        <StyledImage src={logotypy} />
</ImageContainer>
<TextSmall>Zadanie publiczne pn. "Opracowanie strategii zmian transformacji cyfrowej w zakresie podejście do zarządzania - Agile”<br/> dofinansowane ze środków z budżetu Województwa Mazowieckiego. <br/><br/></TextSmall>

    </Container>;
  return newLocal;
}

export default SummaryScreen;
