export const questions = [
        { 
            text: 'Moi pracownicy często odczuwają frustrację z powodu opóźnień w realizacji projektów.', 
            answers: [
                { text: 'Zdecydowanie się nie zgadzam', points: 1 }, 
                { text: 'Raczej się nie zgadzam', points: 2 }, 
                { text: 'Nie mam zdania', points: 3 }, 
                { text: 'Raczej się zgadzam', points: 4 },
                { text: 'Zdecydowanie się zgadzam', points: 5 }
            ] 
        },
        { 
            text: 'Moim pracownikom trudno jest sobie poradzić z niespodziewanymi zmianami wymagań klienta.', 
            answers: [
              { text: 'Zdecydowanie się nie zgadzam', points: 1 }, 
              { text: 'Raczej się nie zgadzam', points: 2 }, 
              { text: 'Nie mam zdania', points: 3 }, 
              { text: 'Raczej się zgadzam', points: 4 },
              { text: 'Zdecydowanie się zgadzam', points: 5 },
            ] 
        },
        { 
            text: 'Moi pracownicy trudno znoszą niespodziewane zmiany w projekcie.', 
            answers: [
              { text: 'Zdecydowanie się nie zgadzam', points: 1 }, 
              { text: 'Raczej się nie zgadzam', points: 2 }, 
              { text: 'Nie mam zdania', points: 3 }, 
              { text: 'Raczej się zgadzam', points: 4 },
              { text: 'Zdecydowanie się zgadzam', points: 5 },
            ] 
        },
        { 
            text: 'Komunikacja między zespołami w mojej firmie jest nieprzejrzysta i ciężko jest określić na jakim etapie projektu jesteśmy.', 
            answers: [
              { text: 'Zdecydowanie się nie zgadzam', points: 1 }, 
              { text: 'Raczej się nie zgadzam', points: 2 }, 
              { text: 'Nie mam zdania', points: 3 }, 
              { text: 'Raczej się zgadzam', points: 4 },
              { text: 'Zdecydowanie się zgadzam', points: 5 },
            ] 
        },
        { 
            text: 'Brak efektywnego planowania i organizacji pracy to czynniki, przez które pracownicy mojej firmy mają słabe poczucie skuteczności.', 
            answers: [
              { text: 'Zdecydowanie się nie zgadzam', points: 1 }, 
              { text: 'Raczej się nie zgadzam', points: 2 }, 
              { text: 'Nie mam zdania', points: 3 }, 
              { text: 'Raczej się zgadzam', points: 4 },
              { text: 'Zdecydowanie się zgadzam', points: 5 },
            ] 
        },
        { 
            text: 'Moi pracownicy przejawiają brak motywacji i zaangażowania w swoją pracę.', 
            answers: [
              { text: 'Zdecydowanie się nie zgadzam', points: 1 }, 
              { text: 'Raczej się nie zgadzam', points: 2 }, 
              { text: 'Nie mam zdania', points: 3 }, 
              { text: 'Raczej się zgadzam', points: 4 },
              { text: 'Zdecydowanie się zgadzam', points: 5 },
            ] 
        },
        { 
            text: 'Zespoły w mojej firmie pracują w izolacji, nie dzielą się informacjami oraz wiedzą z pozostałymi zespołami.', 
            answers: [
              { text: 'Zdecydowanie się nie zgadzam', points: 1 }, 
              { text: 'Raczej się nie zgadzam', points: 2 }, 
              { text: 'Nie mam zdania', points: 3 }, 
              { text: 'Raczej się zgadzam', points: 4 },
              { text: 'Zdecydowanie się zgadzam', points: 5 },
            ] 
        },
        { 
            text: 'Moja firma nie radzi sobie z elastycznością i szybkością reakcji na zmieniające się warunki rynkowe.', 
            answers: [
              { text: 'Zdecydowanie się nie zgadzam', points: 1 }, 
              { text: 'Raczej się nie zgadzam', points: 2 }, 
              { text: 'Nie mam zdania', points: 3 }, 
              { text: 'Raczej się zgadzam', points: 4 },
              { text: 'Zdecydowanie się zgadzam', points: 5 },
            ] 
        },
        { 
            text: 'Do skutecznego przeprowadzenia procesu projektowego jest nam potrzebne zaangażowanie klienta.', 
            answers: [
              { text: 'Zdecydowanie się nie zgadzam', points: 1 }, 
              { text: 'Raczej się nie zgadzam', points: 2 }, 
              { text: 'Nie mam zdania', points: 3 }, 
              { text: 'Raczej się zgadzam', points: 4 },
              { text: 'Zdecydowanie się zgadzam', points: 5 },
            ] 
        },
        { 
            text: 'Moja firma odczuwa silną potrzebę wprowadzania innowacji i szybkiego dostosowywania się do zmieniających potrzeb klientów w branży, dla której realizujemy projekty.', 
            answers: [
              { text: 'Zdecydowanie się nie zgadzam', points: 1 }, 
              { text: 'Raczej się nie zgadzam', points: 2 }, 
              { text: 'Nie mam zdania', points: 3 }, 
              { text: 'Raczej się zgadzam', points: 4 },
              { text: 'Zdecydowanie się zgadzam', points: 5 },
            ] 
        },
        { 
            text: 'Moja firma odczuwa dużą potrzebę częstszego dostarczania wartości klientom.', 
            answers: [
              { text: 'Zdecydowanie się nie zgadzam', points: 1 }, 
              { text: 'Raczej się nie zgadzam', points: 2 }, 
              { text: 'Nie mam zdania', points: 3 }, 
              { text: 'Raczej się zgadzam', points: 4 },
              { text: 'Zdecydowanie się zgadzam', points: 5 },
            ] 
        },
      ];